import _resolveException from "../lib/resolve-exception";
import _is from "./is";
var exports = {};
var resolveException = _resolveException,
    is = _is;

exports = function (value
/*, options*/
) {
  if (is(value)) return value;
  return resolveException(value, "%v is not a plain function", arguments[1]);
};

export default exports;